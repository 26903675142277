import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="ABout Oravro Gaming LTD" keywords={[`oravro`, `gaming`, `money`, `invest`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
        
        <h3>Invest in yourself without excuses</h3>
        <figure className="kg-card kg-image-card kg-width-full">
                    <Img
              fluid={data.lol.childImageSharp.fluid}
              className="kg-image"
            /></figure>
        
          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
           "The exchange is like a melting pot, it boilers people, their most hidden instincts mixes sometimes with money and sometimes with mud, but, remember, it never sleeps"." - from book <b>Much to lose</b>
          </h2>
          <figure className="kg-card kg-image-card kg-width-full">
            <Img
              fluid={data.benchAccounting.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption> My best team I have ever worked with ! </figcaption>
          </figure>
          <h3 id="dynamic-styles">A dynamic game is really our lives !!</h3>
          <p>
 My favorite character has a well organized life. Unexpectedly everything starts to fall apart. We accompany the hero on a long journey from the Manhattan Highlands to the desert of New Mexico. A whirlwind of death unfolds around him, and reality - just now calm and predictable - suddenly shows wolf fangs

What do you think this blog is about?
          </p>
          <p>
"Stretcher.... Please.
Imagine two guys from the gym talking about T-shirts.
- Oh, you have a cool T-shirt!
- Yeah, and you know it gives you a boost of commitment to
training, increasing self-confidence and mental strength?"
You see that?
The second example:
"You will learn the right messages to use in
The conversation will bring agreement. This will certainly allow
You avoid many conflicts and a constant struggle for rations."
My reaction:
"Will you learn the right messages"????
Well, please.
Imagine how two neighbors talk about children and
one tells the other:
"You know, I've learned the right messages, which
the application brings agreement with my children."
You got it?
😊 "
Perhaps you already see this error?
Maybe you don't see it, not me judging you.
But do you know how to fix it?
Oh, dear, I'm sorry, that's a bad question.
I'm a very bad person.
I put you under a wall and say: decide!

          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "52.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 6800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lol: file(
      relativePath: { eq: "oravro-banner.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
